<template>
  <section id="banner_kv" :style="{ backgroundImage: `url(${bg})` }" :class="{ noBg: !backgroundPic }">
    <div class="text">
      <h1 class="zh-title" style="margin-bottom:10px;">
        {{ titleZh }}
      </h1>
      <h2 class="eng eng-title">
        {{ titleEn }}
        <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
      </h2>
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1920 157" enable-background="new 0 0 1920 157" xml:space="preserve">
      <path :fill="bgcolor ? bgcolor : '#f8f8f8'" d="M0,6c0,0,354,151,960,151c652,0,960-151,960-151v151H0V6z" />
      <image overflow="visible" width="1920" height="157" xlink:href="@/assets/img/banner_bg.png">
      </image>
    </svg>
  </section>
</template>

<script>
export default {
  name: 'BannerKv',
  props: ['titleEn', 'titleZh', 'backgroundPic', 'backgroundPicRwd', 'bgcolor'],
  data() {
    return {
      bg: this.backgroundPic,
    };
  },
  mounted() {
    if (window.innerWidth <= 768 && this.backgroundPicRwd) {
      this.bg = this.backgroundPicRwd;
    }
  },
};
</script>

<style scoped>
.zh-title {
  font-size: 1.88rem;
  letter-spacing: 2px;
  /* font-weight: 700; */
}

.eng-title {
  font-size: 1.12rem;
  letter-spacing: 1.35px;
}
</style>
